// src\pages\Careers\components\Search\Filter\Filter.module.scss

@import '../../../../../styles/mdr';

.filterForm {
  @include margin(0 0 25px 0);

  @include media-breakpoint-up(lg) {
    border: 1px solid #bebebe;
  }

  @include media-breakpoint-down(md) {
    .filterGroup {
      @include margin(10px 0);

      display: block;
      max-width: 100%;
      width: 100%;

      input {
        @include margin-bottom(15px);

        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
      }

      .filterPrepend {
        display: none;
      }
    }
  }

  .inputFilter {
    @include media-breakpoint-up(lg) {
      border: 0;
    }

    &:focus,
    &:active {
      box-shadow: none;
      outline: 0;
    }

    &.keywordFilter {
      position: relative;
    }

    &.locationFilter {
      @include padding-right(100px);
    }
  }

  .divider {
    @include media-breakpoint-down(md) {
      display: none;
    }

    height: 80%;
    width: 1px;
    background-color: #bebebe;
    position: relative;
    top: 10%;
    z-index: 9999;
  }

  .filterPrepend {
    span {
      @include media-breakpoint-up(md) {
        border: 0;
      }

      background-color: transparent;
    }
  }

  .submitFilter {
    z-index: 10;
    @include media-breakpoint-up(lg) {
      right: 10px;
      top: 9px;
      position: absolute;
      // z-index: 9999;
    }

    @include media-breakpoint-down(md) {
      @include margin(5px 0);
    }
  }
}
