// src\pages\JobBoard\Page\Page.module.scss

@import '../../../styles/mdr';

.resultPage {
  .jobDetailsContainer {
    position: relative;
    min-height: 100px;
  }

  .resultSidebar {
    @include media-breakpoint-up(lg) {
      @include margin-top(-125px);

      max-width: 200px;
    }
  }
}
