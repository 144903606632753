@import '../../../styles/mdr';

.resultOptions {
  @include padding-top(12px);
  @include padding-bottom(12px);
  @include margin-bottom(15px);

  button + button {
    @include margin-left(10px);
  }
}

.applicantsList {
  border: 1px solid $light-gray;
  border-radius: 5px;

  @include padding(18px);

  &.hired {
    opacity: 0.4;
    pointer-events: none;
  }
}

.applicantsListWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: $graphik;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    position: relative;
  }

  .appStatus {
    position: absolute;
    top: 15px;
    right: 15px;

    button {
      @include font-size(12px);
    }
  }

  .imgWrapper {
    @include media-breakpoint-down(sm) {
      @include margin-top(0);

      order: 2;
    }

    img {
      width: 80px;
      height: auto;
    }
  }

  .appDetails {
    flex: 0 0 calc(100% - 200px);
    max-width: calc(100% - 200px);

    @include padding-left(15px);

    font-family: $graphik;

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;

      @include padding-left(0);
      @include margin-top(10px);

      order: 3;
    }

    .appName {
      @include font-size(18px);
      @include margin-bottom(4px);

      line-height: 26px;
      letter-spacing: -0.28px;
      font-weight: 700;

      a {
        color: darken($branding1, 30%);
      }
    }

    .appContact {
      @include font-size(14px);

      font-weight: 500;

      span {
        border-right: 1px solid $gray;
        padding-right: 8px;
        margin-right: 8px;

        @include media-breakpoint-down(sm) {
          display: block;
          border: none;
          margin: 0;
          padding: 0;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 0;
          border: none;
        }
      }
    }

    .appAbout {
      @include font-size(12px);

      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.19px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include margin-top(8px);

      > div {
        @include margin-right(15px);

        a {
          display: flex;
          align-items: flex-end;

          svg {
            @include margin-right(5px);
            @include font-size(14px);
          }

          span {
            line-height: 1;
            display: inline-block;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}
