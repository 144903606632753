// src\pages\JobBoard\Page\components\More\More.module.scss

@import '../../../../../styles/mdr';

.moreResults {
  .moreLabel {
    @include font-size(22px);
    @include margin-top(35px);
    @include padding-top(35px);
    @include padding-bottom(15px);

    border-top: 1px solid #d4d4d4;
    font-family: $graphik;
    font-weight: 500;
    line-height: 1.18;
    letter-spacing: -0.61px;
    color: $dark-gray;
  }
}
