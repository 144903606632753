// src\styles\abstracts\_variables.scss

$branding1: #166ff9 !default;
$branding1-1: #ebf3ff !default;
$branding1-2: #e1fff1 !default;
$branding2: #ff2400 !default;
$branding2-1: #f6f0ff !default;
$branding3: #fff7b2 !default;
$branding3-1: #fffddd !default;

// $light-pink: #fff3f1 !default;
$white: #fff !default;
$black: #000 !default;
$light-gray: #d7d7d7 !default;
$gray-e: #eeeeee !default;
$gray-f: #f9f9f9 !default;
$gray: #909090 !default;
$light-pink: #ffe6e6 !default;
$medium-gray: #323232 !default;
$medium-500: #5a5a5a !default;
$dark-gray: #1c1c1c !default;
$red: #d93025 !default;
$light-blue: #e9f5ff !default;
$blue: #097ad0 !default;
$xlight-blue: #09B1D0 !default;
$error-box: #ffeeee !default;
$error: #ff7b7b !default;
$xxlight-blue: #f8fffc !default;
$xlight-green: #c6fce4 !default;
$blue-100: #fafdff !default;
$blue-600: #89ccff !default;
$green: #78ffbf !default;
$purple: #b84bd1 !default;
$burgundy: #AF1900 !default;
$orange: #F09916 !default;
$light-green: #AF1900 !default;
$orange-f: #ff2400 !default;

$graphik: 'Graphik',
sans-serif;
$opensans: 'Open Sans',
sans-serif;