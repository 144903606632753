@import '../../styles/mdr';

.aboutPage {
  @include margin-top(80px);
  font-family: $graphik;
  @include padding(0 15px 80px);

  @include media-breakpoint-down(md) {
    @include padding(0 15px);
  }
}
