@import '../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);
}

.freeCred {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.25px;
  color: #323232;
}

.line {
  border-bottom: solid 1px #d3d3d3;
  height: 1px;
  width: 100%;
  margin-bottom: 46px;
}

:global {
  .btn-grey-ghost {
    border-radius: 3rem;
    font-family: $graphik;
    font-weight: 500;
    line-height: 1;
    border: 1px solid #c4c4c4;
    background-color: #008448;
    color: #fff !important;
    text-align: center;
    width: 70%;
    display: block;
    margin-bottom: 42px;
    @include padding(15px 50px);
    font-weight: 600;
    &:hover {
      opacity: 0.9;
      color: $white;
    }
  }
}

.noCertificate {
  @include margin-bottom(40px);
  @include padding(20px 15px 32px);
  border: 1px solid lighten($medium-500, 55%) ;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @include media-breakpoint-down(sm) {
    @include padding(15px);
  }

  .noCertBadge {
    @include margin-bottom(8px);
  }

  .noResumeHeading {
    @include font-size(18px);
    font-weight: 700;
  }

  .noResumeDate {
    @include font-size(14px);
    font-weight: 500;
    letter-spacing: -0.22px;
  }
}

.modalWrappper {

  .authFormWrap {
    @include media-breakpoint-down(md) {
      max-width: 540px;
      margin: 0 auto;
    }

    .modalUpload {
      ul {
        display: none;
      }
    }

    .authFormTitle {
      @include font-size(28px);
      @include margin-bottom(25px);

      font-family: $graphik;
      font-weight: bold;
      line-height: 1.21;
      letter-spacing: -0.78px;
      color: #0f0f0f;
    }

    .authLabel {
      @include font-size(16px);
      @include margin-bottom(5px);

      font-family: $graphik;
      font-weight: bold;
      letter-spacing: -0.44px;
      color: $black;
    }

    .authInput {
      @include margin-bottom(30px);

      position: relative;

      .errorInput {
        border-color: $red;
      }
    }

    .authSubText {
      @include padding(15px 0);
      @include font-size(16px);

      color: #5a5a5a;
      text-align: center;

      a {
        font-family: $graphik;
        font-weight: bold;
        text-decoration: underline;

        @include margin-left(10px);

        &:visited {
          @extend a;
        }
      }
    }
  }

  .modalHeader {
    border: none;
    .modalTitle {

      @include font-size(28px);

      font-family: $graphik;
      font-weight: bold;
      line-height: 1.21;
      letter-spacing: -0.78px;
      color: #0f0f0f;
    }
  }
}
