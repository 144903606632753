@import '../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);
}

.resumeListing {

  margin-bottom: 32px;

  .listing {
    @include margin-bottom(20px);
    @include padding(25px);
    border: 1px solid #f2daff;
    background: #fdfaff;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      @include padding(15px);
    }

    .badge {
      flex: 0 0 95px;
      max-width: 95px;

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
        @include margin-bottom(10px);
      }
    }

    .deleteCert {
      color: red;
      margin-left: 20px;
    }

    .resumeListRight {
      flex: 0 0 calc(100% - 95px);
      max-width: calc(100% - 95px);
      @include font-size(14px);

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .resumeHeading {
        @include font-size(18px);
        font-weight: 700;
      }
      .resumeData,
      .resumeInstitute {
        font-weight: 500;
        line-height: 26px;
      }

      .resumeDate {
        line-height: 22px;
      }
    }
    &.acaListing {
      border: 1px solid $blue-600;
      background: $blue-100;
    }
  }
}