// src\pages\JobBoard\Page\components\Content\Content.module.scss

@import '../../../../../styles/mdr';

.resultContent {
  .resultDescription {
    @include margin(35px 0 0 0);

    font-family: $opensans;
    line-height: 1.5;
    letter-spacing: -0.44px;
    color: #909090;
  }

  .resultAbout {
    @include margin(50px 0 0 0);
    @include font-size(16px);

    font-family: $opensans;
    line-height: 1.5;
    letter-spacing: -0.44px;
    color: #909090;
    position: relative;

    pre {
      @include font-size(16px);
      overflow: unset;
      white-space: pre-wrap;
      font-family: $opensans;
      line-height: 1.5;
      letter-spacing: -0.44px;
      color: $gray;
      position: relative;
    }

    button {
      position: absolute;
      right: 0;
      top: 5px;
      @include media-breakpoint-down(md) {
        position: static;
        margin: 10px 0;
      }
    }

    .resultAboutLabel {
      @include font-size(22px);
      @include padding(10px 0);

      font-family: $graphik;
      font-weight: 500;
      line-height: 1.18;
      letter-spacing: -0.61px;
      color: $dark-gray;
    }
  }
}
