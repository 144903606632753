@import '../../styles/mdr';

.caseStudyWrapper {
  min-height: 500px;
  font-family: $graphik;
  margin-top: -100px;
  z-index: 1;
  position: relative;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

.caseStudyBanner {
  display: flex;
  align-items: flex-end;
  min-height: 404px;
  @include padding(35px 0);


  @include media-breakpoint-down(md) {
    min-height: 240px;
  }

  .compLogo {
    @include padding-right(30px);

    img {
      max-width: 102px;
      mix-blend-mode: darken;
    }
  }

  .compInfo {
    .caseLable {
      @include font-size(20px);
      letter-spacing: 1.33px;
      color: $branding1;
      font-weight: 500;
      text-transform: uppercase;
    }

    .compName {
      @include font-size(40px);
      letter-spacing: -1.11px;
      color: $dark-gray;
      font-weight: 500;
      line-height: 1.2;
      @include padding(05px 0 12px);
    }
  }
}

.caseStudyContent {
  @include padding(40px 0 70px);

  .gridHeading {
    @include font-size(18px);
    font-weight: 500;
    color: $black;
    @include margin-bottom(35px);
  }

  .gridDescription {
    @include padding-right(60px);


    @include media-breakpoint-down(lg) {
      @include padding-bottom(50px);
    }

    @include media-breakpoint-down(md) {
      @include padding-right(0px);
    }

    p {
      @include font-size(20px);
      line-height: 30px;
      color: $medium-gray;
      font-family: $graphik;
      letter-spacing: -0.31px;
      font-weight: normal;
    }
  }
}

.featureList ul {
  padding: 0;
}

.featureList ul li{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.22px;
  margin-bottom: 35px;
  @include padding-right(80px);

  @include media-breakpoint-down(md) {
    @include padding-right(0px);
  }
}

.featureList ul li> span {
  display: flex;
}
.featureList ul li .imgSec {
  min-width: 70px;
  justify-content: center;
  margin-right: 35px;
}

.caseStudyTestimonal {
  background: url(../../assets/imgs/CaseStudy/testimonial@2x.jpg) no-repeat center center;
  background-size: cover;
  @include padding(120px 0);

  .testiDescription {
    @include font-size(40px);
    line-height: 50px;
    letter-spacing: -0.63px;
    color: $white;
    @include margin-bottom(50px);

    @include media-breakpoint-down(md) {
      @include font-size(30px);
      line-height: 1.2;
    }
  }

  .testiUserDetails {
    color: $white;
    .testiName {
      @include font-size(32px);
      line-height: 1.2;
      font-weight: bold;
      letter-spacing: -0.5px;
    }

    .testiAbout {
      @include font-size(24px);
      letter-spacing: -0.38px;
    }
  }

  .testiLogo {
    margin-left: auto;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-top: 10px;
    }
    img {
      max-width: 92px;
    }
  }

  .testiInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .testUser {
      img {
        max-width: 90px;
        margin-right: 25px;

        @include media-breakpoint-down(md) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}


.caseStudyResults {
  @include padding(80px 0);

  .resultsHeading {
    @include font-size(32px);
    letter-spacing: -0.5px;
    line-height: 42px;
    font-weight: 500;
    @include margin-bottom(60px);
  }

  .resultSubHeading {
    border-top: 1px solid $gray;
    @include padding-top(30px);
    @include font-size(35px);
    font-weight: 500;
    letter-spacing: -0.78px;
    line-height: 1;
    min-height: 50px;
  }

  .resultsInfo {
    @include font-size(22px);
    @include padding-right(60px);

    @include media-breakpoint-down(md) {
      @include padding-right(0px);
      @include margin-bottom(30px);
    }
    letter-spacing: -0.34px;
  }

  .resultsInfoHeading {
    @include font-size(32px);
    font-weight: 500;
    line-height: 1.3;
    @include padding-right(60px);

    @include media-breakpoint-down(md) {
      @include padding-right(0px);
    }
  }

  .resultsInfoDesc {
    @include padding-right(60px);

    @include media-breakpoint-down(md) {
      @include padding-right(0px);
    }
    p {
      @include font-size(20px);
      font-family: $graphik;
      line-height: 1.5;
      color: $medium-gray;
    }
  }
}

.carrerMapWrapper {
  background: $light-pink;
  text-align: center;
  @include padding-bottom(60px);
  @include margin-top(80px);

  .mapInnerWrapper {
    img {
      max-width: 107px;
      height: auto;
      position: relative;
      margin-top: -60px;
      @include margin-bottom(24px);
    }
  }

  .mapInnerDetails {
    @include padding-right(140px);
    @include padding-left(140px);

    @include media-breakpoint-down(md) {
      @include padding-right(0px);
      @include padding-left(0px);
    }
    p {
      @include font-size(20px);
      line-height: 1.5;
      font-family: $graphik;
      color: $medium-gray;

      a {
        min-width: 152px;
        background: $orange-f;
        @include font-size(14px);
        line-height: 35px;
        border-radius: 36px;
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #7f0b00;
        }
      }
    }
}
}

.caseStudyBannerWrapper {
  background: url(../../assets/imgs/CaseStudy/banner-2x.png) no-repeat center bottom;
  background-size: cover;
}

:global {

  .caseStudy-page {
    .footerWrap {
      margin-top: 0;
    }
  }
}