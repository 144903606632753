@import '../../../../styles/mdr';

.mainHeading {
  @include font-size(64px);
  @include margin-bottom(50px);
  font-weight: bold;
  letter-spacing: -2px;
  line-height: 1;
  @include padding-right(160px);

  span {
    display: inline-block;
    position: relative;

    &::before {
      content: '';
      height: 59px;
      width: 369px;
      background: $branding1-1;
      display: block;
      position: absolute;
      z-index: -1;
      left: 2px;
      top: 6px;
      @include media-breakpoint-down(sm) {
        width: 250px;
        height: 40px;
      }
    }
  }
}

p {
  @include font-size(28px);
  letter-spacing: -0.78px;
  line-height: 1.5;
  font-family: $opensans;
  color: $black;

}