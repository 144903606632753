// src\pages\Home\components\Hero\Hero.module.scss

@import '../../../../styles/mdr';

.heroWrap {
  @include margin-top(25px);

  .heroBanner {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 385px;

    .textWrapper {
      position: relative;
      z-index: 3;
    }

    &:before {
      content: '';
      z-index: 2;
      position: absolute;
      max-width: 850px;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 28%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 28%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 28%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
    }

    @include media-breakpoint-down(sm) {
      min-height: 0;
      @include padding(25px 0);
    }

    .heroBannerImage {
      // @include media-breakpoint-down(md) {
      //   display: none;
      // }

      position: absolute;
      right: 16px;
      top: 0;
      width: 840px;
      max-width: 100%;
      box-sizing: border-box;

      @include media-breakpoint-down(sm) {
        height: 100%;
        overflow: hidden;
        border-radius: 0 20px 20px 0;
      }

      img {
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;

        @include media-breakpoint-down(xs) {
          min-height: 300px;
        }
      }
    }

    .heroBubble {

        display: flex;
        justify-content: center;
        flex-direction: column;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 440px;

      width: 100%;

      .heroBubbleData {
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }
      }
    }

    .heroTitle {
      @include font-size(46px);

      font-family: $graphik;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -1.28px;
      color: $dark-gray;

      @include media-breakpoint-down(sm) {
        @include font-size(30px);
      }
    }

    .heroDesc {
      @include font-size(20px);
      @include padding(15px 0);

      font-family: $opensans;
      letter-spacing: -0.67px;
      color: #5a5a5a;
      @include padding-right(100px);

      @include media-breakpoint-down(sm) {
        @include font-size(20px);
      }
    }

    .heroLink {
      font-family: $graphik;
      font-weight: bold;
      color: $branding1;
      @include font-size(14px);

      &:hover {
        color: lighten($branding1, 10%);
      }
    }
  }

  .heroBlock {
    @include margin(50px 0 0);

    .heroBlockWrap {
      @include padding-top(15px);

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      border-top: 1px solid $branding2;

      .heroBlockLabel {
        @include font-size(14px);
        @include padding(15px 0);

        font-family: $graphik;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .heroBlockTitle {
        @include font-size(36px);
        @include padding-bottom(15px);
        @include padding-right(25px);
        @include margin-top(10px);
        font-family: $graphik;
        letter-spacing: -1px;
        line-height: 1;
        color: $black;
        max-width: 200px;

        @include media-breakpoint-down(md) {
          @include padding-right(0px);
          max-width: 100%;
        }
      }

      .heroBlockDesc {
        @include font-size(16px);

        font-family: $graphik;
        font-weight: 300;
        line-height: 1.25;
        letter-spacing: -0.44px;
        color: #909090;
        @include media-breakpoint-down(md) {
          @include margin-bottom(5px);}
      }

      .heroBlockIconWrap {
        position: relative;
        text-align: right;

        .heroBlockIcon {
          position: relative;
          z-index: 1;
          top: 20px;
          left: 15px;
        }

        .heroBlockIconBg {
          position: absolute;
          bottom: 0;
          right: 0;
          margin-right: auto;
          z-index: -1;
        }
      }

      .heroIconCol {
        @include media-breakpoint-up(md) {
          @include padding-right(30px);

          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        @include media-breakpoint-down(md) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &.heroBlockOpportunities {
      .heroBlockLabel {
        color: $branding1;
      }
    }

    &.heroBlockCareers {
      .heroBlockLabel {
        color: $branding1;
      }
    }
  }
}
