// src\components\Button\Button.scss

@import '../../styles/mdr';

.micButton {
  &.micBtn {
    @include font-size(14px);
    @include padding(8px 16px);

    background-color: $branding1;
    border: 1px solid transparent;
    border-radius: 3rem;
    font-family: $graphik;
    color: $white;
    font-weight: 500;
    line-height: 1;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active:focus,
    &.btn-primary:not(:disabled):not(.disabled):active:focus {
      background-color: mix(black, $branding1, 20%);
      border-color: transparent;
      color: $white;
      background-image: none;
      outline: 0;
      box-shadow: none;
    }

    &.black {
      background-color: $black;
      color: $white;

      &:hover {
        background-color: lighten($black, 15%);
      }
    }

    &.transparent {
      background-color: transparent;
      color: $dark-gray;

      &:hover {
        background-color: $branding1;
        color: $white;
      }

      &.white {
        color: $white;

        &:hover {
          color: $dark-gray;
        }
      }
    }

    &.outline {
      border: 1px solid $white;
      background-color: transparent;
      color: $white;

      &:hover {
        background-color: $white;
        color: $dark-gray;
      }

      &.active {
        background-color: transparent;
        border-color: $light-gray;
        color: $light-gray;
      }
    }

    &.outline-gray {
      border: 1px solid #c4c4c4;
      background-color: transparent;
      color: #5a5a5a;

      &:hover {
        background-color: #cecece;
        color: $white;
      }
    }

    &.gradient-blue {
      background-size: 200% auto;
      transition: 0.5s;
      color: $white;
      background-image: linear-gradient(to right, #0897b2 0%, #1ad3f5 50%, #16d9ea 100%);

      &:hover {
        background-position: right center;
      }
    }

    &.blue {
      background-color: $light-blue;
      color: $blue;

      &:hover {
        background-color: darken($light-blue, 5%);
      }
    }

    &.gray {
      background-color: #cecece;
      color: $white;

      &:hover {
        background-color: darken(#cecece, 5%);
      }
    }

    &.light-pink {
      background-color: $light-pink;
      color: $black;

      &:hover {
        background-color: darken($light-pink, 5%);
      }
    }

    &.noRadius {
      border-radius: 0;
    }

    &.large {
      @include padding(15px 50px);
      @include font-size(18px);

      font-family: $graphik;
      font-weight: bold;
      border: 1px solid #c4c4c4;
      border-radius: 3rem;
      transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border ease-in-out;
    }

    &.branding {
      background-color: $branding1;
      color: $white;

      &:hover {
        background-color: lighten($branding1, 5%);
        color: $white;
      }
    }

    // &.green {
    //   background-color: $branding1;
    //   color: $white;

    //   &:hover {
    //     background-color: lighten($branding1, 5%);
    //     color: $white;
    //   }
    // }
    &.green-check {
      background-color: $branding1;
      color: $white;
      &:hover {
        background-color: lighten($branding1, 5%);
        color: $white;
      }
      background-image: url('../../assets/svgs/check.svg');
      background-position: 14px;
      background-repeat: no-repeat;
      padding-left: 30px;
    }
  }
}
