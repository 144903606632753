// src\styles\base\_global.scss

@import '../vendors/rfs';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

html {
  height: 100%;

  body {
    min-height: 100%;
    font-family: $opensans;

    @include font-size(16px);

    a {
      transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out;
    }

    .container {
      max-width: 1024px;
    }

    .alert {
      @include margin-top(15px);
      display: flex;
      justify-content: center;
       > div {
         position: static;
         color: $error;

         @include font-size(12px);
         font-weight: 600;
       }
    }
  }
}

.alert {
  @include padding(8px);
}

.alert-danger {
  border: none;
  background: $error-box;
}