// src\pages\Login\profile.module.scss

@import '../../styles/mdr';

.profileWrapper {
  position: relative;
  min-height: 500px;
}

.headerWrapper {
  position: relative;
  padding: 0 15px;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 138px;
    background-color: $black;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  @include media-breakpoint-down(md) {
    background-color: $black;
    padding: 15px;
  }
}
