// src/components/Header/Header.module.scss

@import '../../styles/mdr';

.headerWrap {
  min-height: 100px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 11;
  @include media-breakpoint-down(md) {
    @include padding(10px 0);
    min-height: 60px;
    display: block;
    &.container-fluid {
      @include padding-left(15px);
      @include padding-right(15px);
    }
  }

  .header {
    padding: 0;
  }

  nav {
    padding: 0;

    .navbar-toggler {
      padding: 0;
      border: none;
      outline: 0;
      span {
        background: none;
        width: 22px;
        height: 2px;
        background: $branding1;
        transition: all 0.2s;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: -6px;
          display: inline-block;
          width: 22px;
          height: 2px;
          transform: rotate(0);
          transform-origin: 00% 00%;
          background: $branding1;
          transition: all 0.2s;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 6px;
          height: 2px;
          display: inline-block;
          background: $branding1;
          width: 22px;
          transition: all 0.2s;
          transform: rotate(0);
          transform-origin: 00% 00%;
        }
      }
      &.active {

        span {
          background: transparent;
          &:before {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
          }
          &:after {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
          }
        }
      }
    }
    &.mdrNav {
      a {
        &.mdrBranding {
          padding: 0;
          margin: 0 15px 0 0;

          @include media-breakpoint-down(xs) {
            svg {
              max-width: 150px;
            }
          }

          &.profilePageLogo {
            display: none;
          }
        }
      }

      .navbar-nav {
        @include media-breakpoint-down(md) {
          @include margin-top(30px);
        }
        .nav-link {
          @include font-size(14px);

          @include media-breakpoint-up(lg) {
            margin: 15px 15px 0 15px;
          }

          color: $black;
          font-family: $graphik;
          font-weight: 500;
          position: relative;
          padding: 0;
          @include media-breakpoint-down(md) {
            @include font-size(16px);
            @include padding-top(10px);
            @include padding-bottom(10px);
            border-bottom: 1px solid $light-gray;
            text-align: center;
          }

          &::after {
            background-color: $branding1;
            content: '';
            display: block;
            height: 1px;
            transition: 0.3s width ease-in-out;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5px;
            width: 0;
            @include media-breakpoint-down(md) {
              display: none;
            }
          }

          &:hover,
          &.active {
            &::after {
              width: 100%;
            }
          }
        }
      }

      .headerButtons {


        a{
          button {
            margin: 0;
          }
          &:first-child {
            button {
            margin-right: 15px;
              @include media-breakpoint-down(md) {
                margin-right: 0;
              }
            }
          }
        }
        @include media-breakpoint-up(lg) {
          margin: 10px 0 0 auto;
        }
        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

           a {
             display: block;
             width: 100%;
             max-width: 320px;
             @include margin-top(20px);

             button {
               width: 100%;
               @include font-size(16px);
               border: 1px solid $light-gray;
             }
           }
        }
      }
    }
  }

  &.alt {
    background-color: $black;

    nav {
      &.mdrNav {
        .navbar-nav {
          .nav-link {
            color: $white;

            &::after {
              background-color: $branding1;
            }
          }
        }
      }
    }
  }
}

// .mainLogo {
//   width: 90px;
// }

.profileInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  @include margin-top(5px);
  @include margin-right(15px);
  @include media-breakpoint-down(md) {
    right: 35px;
    position: absolute;
    @include margin-top(0px);
    @include margin-right(15px);
    top: 0;
  }

  .proifleName {
    .dropdown.dropdownButton{
      .dropdown-item {
        @include padding(5px 10px);
        @include font-size(12px);
        font-family: $graphik;
        font-weight: 500;
        &.active,&:active {
          background-color: $gray;
        }
      }
      .dropdown-menu {
        @include padding(5px 0);
        margin: 10px -15px 0 0 !important;
        // @include media-breakpoint-down(md) {
        //   &.dropdown-menu-right {
        //     right: auto;
        //     left: 0;
        //   }
        // }
      }
      button.btn {
        @include padding(8px 0);
        background: none;
        outline: 0;
        box-shadow: none;
        border: none;
        font-family: $graphik;
        font-weight: 500;
        color: $black;
        text-align: left;
        @include font-size(12px);
        position: relative;

        &:after {
          position: absolute;
          right: -15px;
          top: 13px;
          border-bottom-color: $light-gray;
          border-top-color: $light-gray;
          border-top-width: 6px;
          border-left-width: 5px;
          border-right-width: 5px;
        }
      }
    }
  }

  .profileImg {
    border: 1px solid $light-gray;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    @include margin-right(10px);
    svg {
      width: 20px;
      height: auto;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}


.header .collapse {
  display: none;
}

.header .collapsing {
  height: auto;
}

.header .collapse.active {
  display: block;
}

.profileBody {
  .headerWrap {
    background: $black;
    nav {
      .navbar-toggler{
        span,
        span:before,
        span:after {
          background: $white;
        }
        &.active {
          span {
            background: transparent;
          }
        }
      }
    }
    nav.mdrNav {
      .mdrBranding {
        display: none;

        &.profilePageLogo {
          display: inline-block;
        }
      }
      .navbar-nav {
        .nav-link {
          color: $white;

          &::after {
            background-color: $branding1;
          }
        }
      }
    }
  }
}

.profileBody {
    .headerButtons {
      .micButton.micBtn {
        color: $white;
      }
  }
  .profileInfo {
    .proifleName {
      .dropdown.dropdownButton {
        button.btn {
          color: $white;
        }
      }
    }
  }
}

