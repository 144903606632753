// src\pages\JobBoard\Post\components\Types\Opportunity.module.scss

@import '../../../../../styles/mdr';

.opportunityType {

  :global {
    #multiselectContainerReact {
      > div {
        border-radius: 0;
        min-height: 50px;
      }
    }
  }

  .featureDesc {
    @include font-size(14px);

    @include media-breakpoint-up(lg) {
      @include padding-right(50px);
    }

    font-family: $opensans;
    line-height: 1.57;
    letter-spacing: -0.39px;
    color: #909090;
  }

  .requiredFieldsNote {
    @include font-size(12px);
text-align: right;
    margin-bottom: 5px;
    font-weight: 700;

    span {
      color: $red;
    }
  }
  .additionalCosts {
    @include margin(25px 0 0);
    @include padding(20px);

    border-radius: 4px;
    background-color: #fffddd;

    .addTitle {
      @include font-size(10px);

      font-family: $graphik;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1.43px;
      color: #9c9860;
    }

    .addDesc {
      @include font-size(12px);

      font-family: $graphik;
      font-weight: 500;
      line-height: 1.17;
      letter-spacing: -0.33px;
      color: #484848;
    }
  }

  .formDivider {
    @include margin(25px 0);

    border-top: 1px dashed #d1d1d1;
    height: 1px;
    width: 100%;
    max-width: 100%;
  }

  .featureList {
    @include margin-top(20px);
  }

  .featureList {
    ul {
      text-align: left;
      margin: 0;
      padding: 0;

      li {
        @include padding-left(15px);
        @include font-size(12px);

        @include media-breakpoint-up(md) {
          @include margin-right(10px);

          display: inline-block;
        }

        font-family: $opensans;
        font-weight: 600;
        position: relative;
        line-height: 1.83;
        letter-spacing: -0.33px;
        color: #414141;
        list-style: none;

        &::before {
          position: absolute;
          left: 0;
          content: '✔';
          color: $branding1;
        }
      }
    }
  }

  .limitedPriceData {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .limitedPricing {
      @include font-size(12px);

      font-family: $opensans;
      font-weight: 600;
      letter-spacing: -0.33px;
      color: #414141;
    }

    .limitedPrice {
      @include font-size(22px);

      font-family: $opensans;
      font-weight: bold;
      letter-spacing: -0.61px;

      s {
        color: #9f9f9f;
      }

      b {
        color: #414141;
      }
    }

    .limitedButtons {
      a {
        min-width: 237px;
        height: 35px;
      }

      button {
        min-width: 180px;
      }
    }
  }

  .inputLabel {
    @include font-size(16px);
    @include margin(25px 0 15px);

    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -0.44px;
    color: $black;
    position: relative;

    span {
      font-weight: normal;
      @include margin-left(5px);
    }

    span.requiredField {
      font-weight: bold;
      color: $red;
      @include margin(0 0px 0 5px);
    }
  }

  .checkRow {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 20px;
    }
  }

  .subText {
    @include font-size(12px);
    @include margin(30px 0 0);

    font-family: $opensans;
    font-style: italic;
    line-height: 1.83;
    letter-spacing: -0.33px;
    color: #909090;
  }

  .jobSubmitError {
    margin-top: 25px;
    margin-bottom: -40px;
  }

  .jobSubmitSuccess {
    .apiSuccess {
      color: $branding1;
      font-size: 12px;
      padding: 11px;
      text-align: center;
    }

    margin-top: 20px;
  }

  .posRel {
    position: relative;
  }

  .applyCouponBtn {
    right: 25px;
    top: 9px;
    position: absolute;
    z-index: 9999;
  }

  .error {
    color: $error;

    @include font-size(12px);
    font-weight: 600;
  }
}

.infoHover {
  display: inline-block;

  @include border-radius(50%);

  background: $gray-e;
  color: $gray;
  height: 16px;
  width: 16px;
  line-height: 16px;
  font-weight: 500;
  font-style: italic;
  vertical-align: text-bottom;

  @include margin-left(10px);
  @include margin-right(10px);
  @include font-size(10px);

  text-align: center;
}
